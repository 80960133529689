import React from "react";
import styled from "styled-components";

interface BlurDivProps {
  style?: React.CSSProperties;
  blurDegree: number;
  children: React.ReactNode;
}

const StyledBlurDiv = styled.div<{
  $blurDegree: number;
  $isBlur?: boolean;
}>`
  filter: blur(${(props) => props.$blurDegree}px);
  pointer-events: ${(props) => (props.$blurDegree > 0 ? "none" : "auto")};
`;

const BlurDiv: React.FC<BlurDivProps> = ({ style, blurDegree, children }) => {
  return (
    <StyledBlurDiv style={style} $blurDegree={blurDegree}>
      {children}
    </StyledBlurDiv>
  );
};

export default BlurDiv;
