import React, { useEffect, useState } from "react";
import OnImagesLoaded from "react-on-images-loaded";
import API from "../APIs/API";
import Helper, { StringifiedMovie } from "../APIs/Helper";
import LoadingSpinner from "../UI/LoadingSpinner";
import ExpandingDivider from "../UI/ExpandingDivider";
import BlurDiv from "../UI/BlurDiv";
import NavBar from "../UI/NavBar";
import MovieCard from "../UI/MovieCard";
import MovieModal from "../UI/MovieModal";
import { message } from "antd";
import { useNavigate } from "react-router";
import { ExtendedFilteredMovie } from "../APIs/TMBDApi/TMBDTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import { GenreFilter } from "../UI/Fliters/types";
import MovieFilters from "../UI/Fliters/MovieFliters";
import Logger from "../modules/Logger";

const Home: React.FC = () => {
  const [activeGenreFilters, setActiveGenreFilters] = useState<GenreFilter>({});
  const [activeWatchedFilter, setActiveWatchedFilter] = useState<string>("All");
  const [username, setUsername] = useState<string>();
  const [movieList, setMovieList] = useState<ExtendedFilteredMovie[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<StringifiedMovie | undefined>();
  const [showMoviePosters, setShowMoviePosters] = useState(false);
  const [index, setIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [mylistCount, setMylistCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
  };

  const showModalHandler = async (movieId: number) => {
    const movieDetails = await API.movieDetails(movieId);
    const strigifiedMovie = Helper.stringifyMovie(movieDetails.data);
    setShowModal(true);
    setModalData(strigifiedMovie);
  };

  const updateList = async () => {
    const myList = await API.getMyList();
    if (myList && myList.data.length) {
      setMylistCount(myList.data.length);
    }
  };

  const filterByGenre = (movies: ExtendedFilteredMovie[]) => {
    if (!Object.keys(activeGenreFilters).length) return movies;
    return movies.filter((movie) =>
      movie.genres.some(
        (genre) => activeGenreFilters[genre.name as keyof GenreFilter]
      )
    );
  };

  const filterByWatched = (movies: ExtendedFilteredMovie[]) => {
    if (activeWatchedFilter === "All") {
      return movies;
    }
    return movies.filter((movie) =>
      activeWatchedFilter === "Watched" ? movie.watched : !movie.watched
    );
  };

  useEffect(() => {
    const checkLogin = async () => {
      message.config({
        top: 60,
        duration: 0.85,
      });
      const isLoggedIn = await API.isLoggedIn();
      if (isLoggedIn) {
        const storedUser = await API.getUser();
        setUsername(storedUser?.username || "");
        await updateList();
        await getInitialMovies();
        setLoading(false);
      } else {
        navigate("/login");
      }
    };
    checkLogin();
  }, []);

  const getInitialMovies = async () => {
    const responses = await Promise.all([
      await API.getRecommendations(1),
      await API.getRecommendations(2),
      await API.getRecommendations(3),
    ]);

    const movieList = responses
      .map((response) => response.data)
      .reduce((acc, current) => {
        return acc.concat(current);
      }, []);
    const AIRecommendedMovies = await API.getAIReccomendedMovies();
    setMovieList([...AIRecommendedMovies.data, ...movieList]);
    setCurrentPage(4);
  };

  const onSearch = async (searchValue: string) => {
    setLoading(true);
    setSearchValue(searchValue);
    setCurrentPage(2);
    if (searchValue === "") {
      await getInitialMovies();
    } else {
      const res = await Promise.all([await API.search(searchValue, 1)]);
      const allData = [];
      allData.push(...res[0].data);
      if (allData) {
        setMovieList(allData);
        setShowMoviePosters(false);
        setIndex((prev) => prev + 1);
      }
      setLoading(false);
    }
  };

  const updateGenreFilters = (newFilters: GenreFilter) => {
    setActiveGenreFilters(newFilters);
  };

  const updateWatchedFilters = (newFilters: string) => {
    setActiveWatchedFilter(newFilters);
  };

  const renderMovieCards = () => {
    let filteredMovies = filterByGenre(movieList);
    filteredMovies = filterByWatched(filteredMovies);

    return Helper.removeDuplicates(filteredMovies).map((movie) => (
      <div key={movie.id}>
        <MovieCard
          movieID={movie.id}
          showModal={() => showModalHandler(movie.id)}
          userRating={movie.vote_average}
          imbdRating={movie.imbd_rating}
          posterPath={movie.poster_path}
          title={movie.title}
          isInList={movie.isAdded}
          isWatched={movie.watched}
          updateOnChange={true}
          updateList={updateList}
        />
      </div>
    ));
  };
  const getNextPage = async () => {
    setLoading(true);
    console.log("Loading GetNextPage");

    let nextMovies;
    if (searchValue) {
      nextMovies = await API.search(searchValue, currentPage);
      Logger.log(nextMovies, "nextMovies");
    } else {
      nextMovies = await API.getRecommendations(currentPage);
    }
    if (nextMovies.data.length > 1) {
      setMovieList((prev) => prev.concat(nextMovies.data));
      setCurrentPage(currentPage + 1);
    } else {
    }
    setLoading(false);
    return renderMovieCards();
  };

  if (!movieList.length && searchValue.length === 0) return <LoadingSpinner />;
  return (
    <div>
      {loading && <LoadingSpinner />}
      <NavBar
        onSearchbarChange={(value) => {
          onSearch(value);
        }}
        showLogOutButton={true}
        username={username}
        showMyListIcon={true}
        showSearchBar={true}
        listCount={mylistCount}
      />
      <BlurDiv blurDegree={loading || showModal ? 3 : 0}>
        <ExpandingDivider
          lineColor={"#606060"}
          titleColor={"#dbdbdb"}
          openable={true}
          fontSize={21}
          title={"Filters"}
        >
          <MovieFilters
            onUpdateGenreFilter={updateGenreFilters}
            onUpdateWatchedFilter={updateWatchedFilters}
          />
        </ExpandingDivider>
        <ExpandingDivider
          lineColor={"#606060"}
          openable={false}
          titleColor={"#dbdbdb"}
          fontSize={21}
          title={searchValue === "" ? "Recommended For you" : "Results"}
        />

        <OnImagesLoaded
          key={index}
          onLoaded={() => {
            setShowMoviePosters(true);
            setLoading(false);
          }}
          onTimeout={() => {
            setShowMoviePosters(true);
            setLoading(false);
          }}
          timeout={2}
        >
          <InfiniteScroll
            style={{
              display: showMoviePosters ? "flex" : "none",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
            scrollableTarget="mainContainer"
            dataLength={movieList.length}
            scrollThreshold={0.9}
            next={getNextPage}
            hasMore={true}
            loader={<></>}
          >
            {renderMovieCards()}
          </InfiniteScroll>
        </OnImagesLoaded>
      </BlurDiv>
      {showModal && !loading && modalData && (
        <MovieModal closeModal={closeModal} movie={modalData} />
      )}
    </div>
  );
};

export default Home;
