import React, { useState, useEffect } from "react";

interface EachMovieProps {
  userMovies: {
    poster_path: any;
    original_title: string;
  }[]; // Accepting userMovies as props
  onMovieSelect: (movieIndex: number) => void;
}

const MoviesCircles: React.FC<EachMovieProps> = ({
  userMovies,
  onMovieSelect,
}) => {
  const [selectedCircle, setSelectedCircle] = useState<number | null>(null);
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);

  useEffect(() => {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const animateCircles = async () => {
      for (let i = 1; i <= 3; i++) {
        setSelectedCircle(i);
        await delay(500);
      }

      setSelectedCircle(0);
      setAnimationFinished(true);
    };

    animateCircles();
  }, []);

  // Style typed with React.CSSProperties and dynamic for movie circles
  const moviesCirclesContainerStyle: React.CSSProperties = {
    position: "absolute",
    bottom: 10,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };

  const movieCircleStyle = (circleNumber: number): React.CSSProperties => ({
    width: "80px",
    height: "80px",
    margin: "0 10px",
    borderRadius: "50%",
    cursor: "pointer",
    border:
      selectedCircle === circleNumber
        ? "4px solid green"
        : "6px solid transparent",
    opacity: animationFinished ? 1 : 0,
    transition: "opacity 0.3s ease-in-out",
  });

  const movieImageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  };

  // Handle circle click
  const handleCircleClick = (circleNumber: number) => {
    setSelectedCircle(circleNumber);
    onMovieSelect(circleNumber);
  };

  return (
    <div style={moviesCirclesContainerStyle}>
      {userMovies.map((eachMovie, index) => (
        <div
          key={index}
          style={{
            ...movieCircleStyle(index),
            transitionDelay: `${index * 0.1}s`,
          }}
          onClick={() => handleCircleClick(index)}
        >
          <img
            src={`https://image.tmdb.org/t/p/original${eachMovie.poster_path}`}
            alt={`Movie ${index}`}
            style={movieImageStyle}
          />
        </div>
      ))}
    </div>
  );
};

export default MoviesCircles;
