import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import UserIcon from "./UserIcon";
import AppButton from "./Button";
import SearchBar from "./SearchBar";
import MyListIcon from "./MyListIcon";
import "../Styles/GlowingBtn.css";

import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import API from "../APIs/API";

const StyledNavBar = styled.div`
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
  height: 50px;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 20px;
  justify-content: space-between;
`;

const StyledLogo = styled.img`
  align-self: center;
  display: flex;
  margin: 5px;
`;

const DisplayedUsername = styled.span`
  color: white;
  align-items: center;
  transform: translate(0, 30%);
`;

const RightNavBarItems = styled.div`
  display: flex;
  width: 450px;
  justify-content: flex-end;

  /* Hide items on mobile view */
  @media (max-width: 768px) {
    display: none;
  }
`;

const LeftNavBarItems = styled.div`
  display: flex;
  width: 450px;
  justify-content: flex-start;
`;

const MyListIconDiv = styled.div`
  position: relative;
  top: 10px;
  left: 10px;
`;

const HamburgerIcon = styled.div`
  display: none;
  color: aliceblue;
  font-size: 24px;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

const Drawer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 50px; /* Position right below the navbar */
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")}; /* Adjust height */
  transition: max-height 0.3s ease-in-out; /* Smooth expand/collapse effect */
`;

interface NavBarProps {
  showMyListIcon?: boolean;
  listCount?: number;
  showLoginButton?: boolean;
  showSignUpButton?: boolean;
  showLogOutButton?: boolean;
  showSearchBar?: boolean;
  username?: string;
  onSearchbarChange?: (searchValue: string) => void;
}

const NavBar: React.FC<NavBarProps> = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    await API.logout(() => {
      navigate("/login");
    });
  };

  const buttonsList: Array<{
    icon: React.ReactNode;
    text: string;
    linkTo: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  }> = [];
  if (props.showLoginButton)
    buttonsList.push({
      icon: <LoginOutlined style={{ color: "white", marginRight: "8px" }} />,
      text: "Login",
      linkTo: "/login",
    });
  else if (props.showSignUpButton)
    buttonsList.push({
      icon: <LoginOutlined style={{ color: "white", marginRight: "8px" }} />,
      text: "Sign Up",
      linkTo: "/signup",
    });
  else {
  }

  if (props.showLogOutButton)
    buttonsList.push({
      icon: <LogoutOutlined style={{ color: "white", marginRight: "8px" }} />,
      text: "Logout",
      linkTo: "/login",
      onClick: handleLogout,
    });

  const myListIcon = props.showMyListIcon ? (
    <Link to="/my-list">
      <MyListIconDiv>
        <MyListIcon myListNo={props.listCount || 0} />
      </MyListIconDiv>
    </Link>
  ) : null;
  const userName = props.username?.split("@")[0];
  const usernameAndAvatar = userName ? (
    <div style={{ display: "flex", minWidth: "fit-content" }}>
      <UserIcon username={userName} />
      <DisplayedUsername>{userName}</DisplayedUsername>
    </div>
  ) : null;
  const handleSuggestionClick = () => {
    navigate("/smart-movie-suggestion");
  };

  return (
    <StyledNavBar>
      <LeftNavBarItems>
        <Link style={{ display: "flex" }} to="/">
          <StyledLogo
            src="../MovieLogo.png"
            alt="oops"
            width="auto"
            height="34px"
          />
        </Link>
        {myListIcon}
        {props.showLogOutButton && screenWidth > 730 && (
          <button className="glowing-btn" onClick={handleSuggestionClick}>
            <span className="glowing-txt">SMART SUGGEST</span>
          </button>
        )}
      </LeftNavBarItems>

      {props.showSearchBar && (
        <SearchBar
          onInputChange={(searchValue) =>
            props.onSearchbarChange?.(searchValue)
          }
        />
      )}

      {/* Desktop Navigation */}
      <RightNavBarItems>
        {buttonsList.map((button) => (
          <Link to={button.linkTo} key={button.linkTo}>
            <AppButton
              text={button.text}
              icon={button.icon}
              height={"32px"}
              width={"95px"}
              color={"white"}
              onClick={button.onClick}
              fontSize={"14px"}
              backgroundColor={"rgba(0,0,0,0)"}
              border={"1px solid white"}
            />
          </Link>
        ))}
        {usernameAndAvatar}
      </RightNavBarItems>

      {/* Hamburger Menu for Mobile */}
      {props.showLogOutButton ? (
        <HamburgerIcon onClick={toggleDrawer}>
          <span>☰</span> {/* Replace with an icon if needed */}
        </HamburgerIcon>
      ) : (
        ""
      )}

      {/* Mobile Drawer */}
      <Drawer isOpen={isDrawerOpen}>
        {buttonsList.map((button) => (
          <Link to={button.linkTo} key={button.linkTo}>
            <AppButton
              text={button.text}
              icon={button.icon}
              height={"32px"}
              width={"95px"}
              color={"white"}
              onClick={button.onClick}
              fontSize={"14px"}
              backgroundColor={"rgba(0,0,0,0)"}
              border={"1px solid white"}
            />
          </Link>
        ))}
        {usernameAndAvatar}
      </Drawer>
    </StyledNavBar>
  );
};

export default NavBar;
