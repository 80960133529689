/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling Entire File TS check until migration from JS is done.
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, CSSProperties } from "react";
import Lottie from "lottie-react";
import moviesAnimation from "../../assets/moviesAnimation.json";
import MoviesCircles from "./MoviesCircles";
import EachMovie from "./EachMovie";

interface MoviesPageProps {
  userMovies: { original_title: string }[];
  idObject: { [key: number]: true };
}

const Movies: React.FC<MoviesPageProps> = ({ userMovies, idObject }) => {
  // State with proper typing
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);
  const [currentMovie, setCurrentMovie] = useState<number>(0); // Moved currentMovie to MoviesPage

  // Handler function with typing
  const handleAnimationFinish = (): void => {
    setAnimationFinished(true);
  };

  const handleMovieSelect = (movieIndex: number) => {
    setCurrentMovie(movieIndex); // Update the currentMovie when a circle is clicked
  };

  // Define styles with CSSProperties type
  const moviesContainerStyle: CSSProperties = {
    position: "relative",
    minHeight: "100vh",
    background: animationFinished ? "#003157" : "black",
    transition: "background 0.5s ease",
    color: "white",
    overflow: "hidden",
  };

  const animationWrapperStyle: CSSProperties = {
    position: "absolute",
    top: animationFinished ? "0vh" : "50%",
    left: animationFinished ? "0vh" : "50%",
    transform: animationFinished
      ? "scale(0.0) translate(-50%, -50%)"
      : "translate(-50%, -50%)",
    transition: "top 0.5s ease, left 0.5s ease, transform 0.5s ease",
  };

  const moviesAnimationStyle: CSSProperties = {
    width: animationFinished ? "40%" : "100%",
    transition: "width 0.5s ease-in-out",
  };

  const navBarStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    background: "linear-gradient(to top, transparent, black)",
    width: "100%",
    height: "7%",
    opacity: 0.6,
  };

  return (
    <div style={moviesContainerStyle}>
      {animationFinished && (
        <EachMovie
          userMovies={userMovies}
          currentMovie={currentMovie}
          idObject={idObject}
        />
      )}

      <div style={animationWrapperStyle}>
        <Lottie
          style={moviesAnimationStyle}
          loop={false}
          animationData={moviesAnimation}
          onComplete={handleAnimationFinish}
        />
      </div>
      {animationFinished && (
        <MoviesCircles
          userMovies={userMovies}
          onMovieSelect={handleMovieSelect}
        />
      )}
    </div>
  );
};

export default Movies;
