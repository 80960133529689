import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import aiAnimation from "../../assets/AI.json";
import robotScaningBrain from "../../assets/robotScaningBrain1.json";
import bgBehindScaning from "../../assets/bgBehindScaning.json";

interface InputSecProps {
  onInputChange: (input: string) => void;
  aiStartThinking: boolean;
}

const InputSec: React.FC<InputSecProps> = ({
  onInputChange,
  aiStartThinking,
}) => {
  const [userInput, setUserInput] = useState<string>("");
  const [recommendationsStatus, setRecommendationsStatus] =
    useState<boolean>(false);
  const aiAnimationRef = useRef<any>(null);

  useEffect(() => {
    if (aiStartThinking) {
      setRecommendationsStatus(true);
    } else {
      setRecommendationsStatus(false);
    }
  }, [aiStartThinking]);

  const inputSectionContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "45px",
  };

  const textAreaStyle = {
    width: recommendationsStatus ? "23%" : "40%",
    minHeight: "40vh",
    resize: "none" as const,
    padding: "12px 20px",
    boxSizing: "border-box" as const,
    borderRadius: recommendationsStatus ? "100%" : "15px",
    fontSize: "18px",
    outline: "none" as const,
    zIndex: "1",
    transition: "width 0.5s ease, border-radius 0.5s ease",
  };

  const aiAnimationStyle = {
    width: "13%",
    position: "absolute" as const,
    marginRight: "55%",
    left: !recommendationsStatus ? "22%" : "27.5%",
    opacity: !recommendationsStatus ? 1 : 0,
    transform: `scale(${!recommendationsStatus ? 1 : 0})`,
    transition:
      "opacity 0.4s ease-in-out, transform 0.4s ease-in-out, left 0.5s ease-in-out",
  };

  const getPlaceholderText = (): string => {
    return "Tell us about the movie you want to watch...";
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(event.target.value);
    onInputChange(event.target.value);
  };

  const robotScaningBrainStyle: React.CSSProperties = {
    width: "16%",
    position: "absolute" as const,
    zIndex: "100",
    opacity: recommendationsStatus ? 1 : 0,
    transform: `scale(${recommendationsStatus ? 1 : 0})`,
    transition:
      "opacity 0.4s ease-in-out, transform 0.4s ease-in-out, left 0.5s ease-in-out",
  };

  const bgBehindScaningStyle: React.CSSProperties = {
    width: "30%",
    position: "absolute" as const,
    opacity: recommendationsStatus ? 1 : 0,
    transform: `scale(${recommendationsStatus ? 1 : 0})`,
    transition: "opacity 0.4s ease-in-out, transform 0.4s ease-in-out",
  };

  return (
    <div style={inputSectionContainerStyle}>
      <div style={aiAnimationStyle}>
        <Lottie
          lottieRef={aiAnimationRef}
          loop={true}
          animationData={aiAnimation}
        />
      </div>

      <textarea
        placeholder={recommendationsStatus ? "" : getPlaceholderText()}
        style={textAreaStyle}
        onChange={handleInputChange}
        value={recommendationsStatus ? "" : userInput}
        disabled={recommendationsStatus}
      ></textarea>

      <Lottie
        style={bgBehindScaningStyle}
        loop={recommendationsStatus}
        animationData={bgBehindScaning}
      />
      <Lottie
        style={robotScaningBrainStyle}
        loop={recommendationsStatus}
        animationData={robotScaningBrain}
      />
    </div>
  );
};

export default InputSec;
