/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling Entire File TS check until migration from JS is done.
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import "../../Styles/GetRecommendationsBtn.css";
import SmartMovieSuggestionAPI from "../../APIs/SmartMovieSuggestionAPI";

import axios from "axios";
import React, { useState, useEffect } from "react";

interface GetRecommendationsBtnProps {
  userInput: string;
  setAiStartThinking: (value: boolean) => void;
  setIsConfirmationLoadingFinished: (value: boolean) => void;
  setUserMovies: (movies: { original_title: string }[]) => void; // Added prop
}

const GetRecommendationsBtn: React.FC<GetRecommendationsBtnProps> = ({
  userInput,
  setAiStartThinking,
  setIsConfirmationLoadingFinished,
  setUserMovies,
}) => {
  const [error, setError] = useState<string>("");
  const [rapidAPIKey, setRapidAPIKey] = useState<string | null>(null);
  const [recommendationsStatus, setRecommendationsStatus] =
    useState<boolean>(false);
  //   const [userMovies, setUserMovies] = useState<{ original_title: string }[]>(
  //     []
  //   );

  const rapidAPIKeys = [
    "42b784d278msh9968340461abba7p11162bjsn1e9e2aa283f5", //67
    "b18129b7eamsh49823c12ed46d8fp15fdbajsnb83dd341a2b2", // 848
    "b81f2cbc1amsh75d040f81ce647fp1fa4e1jsn6b50598596df", // yrs
    "10e50224a5msh13c90e024fccde6p1f6d08jsn7a08f5bd9293", //sparkledrive
    "9d08ec770bmshe7c36e50e9a135ep10438cjsn8899f23a2335", //workwithyoussef
    "4402431e98mshc28c91a8db7a4d2p121317jsn573d39e2cbf7", //yekola
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * rapidAPIKeys.length);
    const randomKey = rapidAPIKeys[randomIndex];
    setRapidAPIKey(randomKey);
  }, []);

  const isButtonDisabled = !(userInput.length > 0);

  const askChatGPT = async (userInput: string) => {
    // const moviesNames = userMovies.map((movie) => movie.original_title);

    const content = `here is the user input: ${userInput}. Provide me with a list of 10 excellent movies that match my criteria. Return them in a JSON object under the 'movies' key. The JSON should consist of an array of movie names, each represented as a string 'movie name'. do not number the movies just each element in the movie names array is "movie name"`;
    //   userMovies.length === 0
    //     ? `here is the user input: ${userInput}. Provide me with a list of 10 excellent movies that match my criteria. Return them in a JSON object under the 'movies' key. The JSON should consist of an array of movie names, each represented as a string 'movie name'. do not number the movies just each element in the movie names array is "movie name"`
    //     : `here is the user input: ${userInput}. Provide me with a list of 10 excellent movies that match my criteria. Return them in a JSON object under the 'movies' key. The JSON should consist of an array of movie names, each represented as a string 'movie name'. do not number the movies just each element in the movie names array is "movie name". try to get new movies other than these ${moviesNames}`;

    const options = {
      method: "POST",
      url: "https://chatgpt-api8.p.rapidapi.com/",
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Key": rapidAPIKey || "",
        "X-RapidAPI-Host": "chatgpt-api8.p.rapidapi.com",
      },
      data: [
        {
          content:
            "Hello! I'm an AI assistant bot based on ChatGPT 3. How may I help you?",
          role: "system",
        },
        {
          content,
          role: "user",
        },
      ],
    };

    try {
      const response = await axios.request(options);
      return extractMovies(response.data.text);
    } catch (err) {
      console.error("from chatGPT func: ", err);
      setError("Failed to communicate with ChatGPT. Please try again.");
      return null;
    }
  };

  const extractMovies = (response: string) => {
    try {
      const jsonString = response.match(/{[^]*}/);
      if (jsonString) {
        const responseObject = JSON.parse(jsonString[0]);
        if (responseObject && responseObject.movies) {
          return responseObject.movies;
        } else {
          console.error("Movies array not found in the response.");
          return null;
        }
      } else {
        console.error("No valid JSON object found in the response.");
        return null;
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  const handleConfirmation = async () => {
    setAiStartThinking(true);
    setRecommendationsStatus(true);
    setError("");
    setIsConfirmationLoadingFinished(false);

    try {
      const chatGPTMovies = await askChatGPT(userInput);
      // let chatGPTMovies = ["Mad Max: Fury Road", "The Road"];

      if (chatGPTMovies) {
        const allMoviesFromTMDB = await SmartMovieSuggestionAPI.startTMDBApi(
          chatGPTMovies
        );

        setUserMovies(allMoviesFromTMDB);
        setRecommendationsStatus(false);
        setAiStartThinking(false);
        setIsConfirmationLoadingFinished(true);
      }
    } catch (err) {
      console.log(err);
      setRecommendationsStatus(false);
      setError(
        "Unable to process your request at the moment. Please try again."
      );
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <a
        href="#"
        className={`button ${
          isButtonDisabled || recommendationsStatus ? "buttonDisabled" : ""
        }`}
        onClick={handleConfirmation}
      >
        Get Recommendations
      </a>
      {error && <div style={{ color: "red", paddingTop: "10px" }}>{error}</div>}
    </div>
  );
};

export default GetRecommendationsBtn;
