import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SignUpModal from "../UI/SignUpModal";
import BlurDiv from "../UI/BlurDiv";
import API from "../APIs/API";
import { useNavigate } from "react-router";
import NavBar from "../UI/NavBar";
import LoadingSpinner from "../UI/LoadingSpinner";


const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false);


  const backgroundImage = "/StarsBG.jpg";

  const navigate = useNavigate();
  useEffect(() => {
    const checkLoginStatus = async () => {
      console.log("Loading Signup Page");

      setLoading(true);

      const isLoggedIn = await API.isLoggedIn();
      if (isLoggedIn) {
        navigate("/");
      }
      setLoading(false);

    };
    checkLoginStatus();
  }, [navigate]);

  return (
    <div style={{ height: "100%" }}>
      <NavBar
        showMyListIcon={false}
        showSearchBar={false}
        showLoginButton={true}
        showSignUpButton={false}
        showLogOutButton={false}
      />
      <BlurDiv style={{ height: "100%" }} blurDegree={3}>
        <StyledImage src={backgroundImage} alt="oops" />
      </BlurDiv>
      {loading? <LoadingSpinner /> :<SignUpModal />}
    </div>
  );
};

export default SignUp;
