import axios from "axios";

const API_URL = "https://api.themoviedb.org/3/";
const API_KEY = "29de87d3f58e703ce82ba34e2460edcd";

interface MovieDetails {
  id: number;
  imdb_id: string;
  original_title: string;
  backdrop_path: string;
  genres: { id: number; name: string }[];
  overview: string;
  poster_path: string;
  release_date: string;
  runtime: number;
  ratings?: { imdb: number };
  trailer?: string;
  cast?: { id: number; name: string; character: string }[];
}

class TMDBApi {
  // Main method to process movies
  static async startTMDBApi(moviesNames: string[]): Promise<MovieDetails[]> {
    let moviesIDs = await this.getMoviesIDs(moviesNames);
    const validMovieIDs = this.removeUndefinedFromArray(moviesIDs);

    const moviesDetails = await this.movieDetails(validMovieIDs);
    const moviesDetailsWithRatings = await this.getMoviesRatings(moviesDetails);
    const moviesDetailsWithRatingsAndTrailers = await this.getMoviesTrailers(
      moviesDetailsWithRatings
    );
    return this.getMoviesCast(moviesDetailsWithRatingsAndTrailers);
  }

  // Utility to remove undefined values from array
  static removeUndefinedFromArray<T>(arr: (T | undefined)[]): T[] {
    return arr.filter((item): item is T => item !== undefined);
  }

  // Get cast information for each movie
  static async getMoviesCast(
    moviesDetailsWithRatingsAndTrailers: MovieDetails[]
  ): Promise<MovieDetails[]> {
    for (let movie of moviesDetailsWithRatingsAndTrailers) {
      const url = `${API_URL}movie/${movie.id}/credits?api_key=${API_KEY}`;
      movie.cast = await axios
        .get(url)
        .then((res) => res.data.cast.splice(0, 5))
        .catch((err) => {
          console.error(err);
          return [];
        });
    }
    return moviesDetailsWithRatingsAndTrailers;
  }

  // Get trailers for each movie
  static async getMoviesTrailers(
    moviesDetailsWithRatings: MovieDetails[]
  ): Promise<MovieDetails[]> {
    for (let movie of moviesDetailsWithRatings) {
      const url = `${API_URL}movie/${movie.id}/videos?api_key=${API_KEY}`;
      const youtubeKey = await axios
        .get(url)
        .then((res) => {
          const trailer = res.data.results.find(
            (video: any) =>
              (video.name === "Trailer" || video.type === "Trailer") &&
              video.site === "YouTube"
          );
          return trailer ? trailer.key : undefined;
        })
        .catch((err) => {
          console.error(err);
          return undefined;
        });

      movie.trailer = youtubeKey;
    }

    return moviesDetailsWithRatings;
  }

  // Get IMDb ratings for each movie
  static async getMoviesRatings(
    moviesDetails: MovieDetails[]
  ): Promise<MovieDetails[]> {
    const options = {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": "42b784d278msh9968340461abba7p11162bjsn1e9e2aa283f5",
        "X-RapidAPI-Host": "moviesdatabase.p.rapidapi.com",
      },
    };

    for (let movie of moviesDetails) {
      const url = `https://moviesdatabase.p.rapidapi.com/titles/${movie.imdb_id}/ratings`;
      try {
        const response = await axios.request({ ...options, url });
        movie.ratings = {
          imdb: response.data.results?.averageRating || 0,
        };
      } catch (error) {
        console.error(error);
        movie.ratings = { imdb: 0 };
      }
    }

    return moviesDetails;
  }

  // Fetch movie IDs based on movie names
  static async getMoviesIDs(
    moviesNames: string[]
  ): Promise<(number | undefined)[]> {
    const moviesIDs = await Promise.all(
      moviesNames.map(async (name) => {
        const url = `${API_URL}search/movie?api_key=${API_KEY}&query=${name}`;
        return axios
          .get(url)
          .then((res) => res.data.results[0]?.id)
          .catch((err) => {
            console.error(err);
            return undefined;
          });
      })
    );

    return moviesIDs;
  }

  // Get details for each movie by ID
  static async movieDetails(moviesIDs: number[]): Promise<MovieDetails[]> {
    const eachMovieWantedAttributes = [
      "backdrop_path",
      "genres",
      "imdb_id",
      "original_title",
      "overview",
      "poster_path",
      "release_date",
      "runtime",
    ];

    const moviesDetails = await Promise.all(
      moviesIDs.map(async (id) => {
        const url = `${API_URL}movie/${id}?api_key=${API_KEY}`;
        const movie: Partial<MovieDetails> = { id };
        return axios
          .get(url)
          .then((res) => {
            for (const attr of eachMovieWantedAttributes) {
              movie[attr as keyof MovieDetails] = res.data[attr];
            }
            return movie as MovieDetails;
          })
          .catch((err) => {
            console.error(err);
            return undefined;
          });
      })
    );

    return this.removeUndefinedFromArray(moviesDetails);
  }
}

export default TMDBApi;
