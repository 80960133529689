import React from "react";
import styled from "styled-components";
import YouTube from "react-youtube";
import Modal from "./Modal";
import IMDbRating from "./IMDbRating";
import { StringifiedMovie } from "../APIs/Helper";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

interface MovieModalProps {
  closeModal: () => void;
  movie: StringifiedMovie;
}

const OuterDiv = styled.div`
  display: flex;
  width: 80vw;
  height: 60vh;
  margin: -15px 0px;
  max-width: 1200px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #303030;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 619px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    max-height: 500px;
  }
`;
const UserAverageContainer = styled.div`
  height: 10%;
  width: 10%;
  max-width: 50px;
  max-height: 50px;
  display: inline-table;
`;

const AllInfoDiv = styled.div`
  width: 56%;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 619px) {
    width: 100%;
  }
`;

const AddAndIMDbDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 35px;
  align-items: end;
`;

const IMDbInfoDiv = styled.div`
  max-height: 180px;
  color: #c1c1c1;
  font-size: 18px;
  padding-top: 10px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #303030;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StaffInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #828282;
  font-size: 14px;
  margin: 10px;
`;

const VideoDiv = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  max-height: 60%;
  justify-content: center;
  padding-top: 20px;
`;

const StyledPoster = styled.img`
  align-self: center;
  margin: 10px;
  width: 44%;
  height: 97%;
  object-fit: contain;

  @media (max-width: 619px) {
    width: 80%;
  }
`;

const MovieModal: React.FC<MovieModalProps> = ({ movie, closeModal }) => {
  const opts = {
    height: "auto",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const _onReady = (event: { target: { pauseVideo: () => void } }) => {
    event.target.pauseVideo();
  };
  const ratingPercentage = Math.ceil(Number(movie.vote_average) * 10);

  return (
    <Modal closable={true} closeModal={closeModal}>
      <OuterDiv>
        <StyledPoster src={movie.poster_path} alt="Movie Poster" width="auto" />
        <AllInfoDiv>
          <h1
            style={{ color: "#c1c1c1", marginBottom: "0px", fontSize: "32px" }}
          >
            {movie.title}
          </h1>
          <AddAndIMDbDiv>
            {movie.imbd_rating && <IMDbRating rating={movie.vote_average} />}
            {movie.vote_average && (
              <UserAverageContainer>
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: `rgba(210, 213, 48, ${ratingPercentage / 100})`,
                    textColor: "#fff",
                    trailColor: "#413d10",
                    backgroundColor: "#081c22",
                    textSize: "180%",
                  })}
                  background={true}
                  strokeWidth={6}
                  value={ratingPercentage}
                  text={`${ratingPercentage}%`}
                />
              </UserAverageContainer>
            )}
          </AddAndIMDbDiv>
          <IMDbInfoDiv>
            <div style={{ textAlign: "left", padding: "0px 35px" }}>
              {movie.runTime} minutes | {movie.genres} | {movie.release_date}
            </div>
            <StaffInfoDiv>
              <div style={{ marginLeft: "23px" }}>Cast: {movie.cast}</div>
            </StaffInfoDiv>
            <div style={{ padding: "0px 35px" }}>{movie.info}</div>
          </IMDbInfoDiv>
          <VideoDiv>
            <YouTube
              iframeClassName={"YoutubeIframClass"}
              style={{ position: "relative", width: "100%", height: "100%" }}
              videoId={movie.trailerID}
              opts={opts}
              onReady={_onReady}
            />
          </VideoDiv>
        </AllInfoDiv>
      </OuterDiv>
    </Modal>
  );
};

export default MovieModal;
