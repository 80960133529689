/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling Entire File TS check until migration from JS is done.
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import InputSec from "../UI/SmartMovieSuggestion/InputSec";
import GetRecommendationsBtn from "../UI/SmartMovieSuggestion/GetRecommendationsBtn";
import MoviesPage from "../UI/SmartMovieSuggestion/MoviesPage";
import API from "../APIs/API";
import NavBar from "../UI/NavBar";

const SmartMovieSuggestion: React.FC = () => {
  const [userInput, setUserInput] = useState("");
  const [aiStartThinking, setAiStartThinking] = useState(false);
  const [username, setUsername] = useState<string | undefined>();
  const [movieList, setMovieList] = useState<ExtendedFilteredMovie[]>([]);
  const [isConfirmationLoadingFinished, setIsConfirmationLoadingFinished] =
    useState(false);
  const moviesRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [userMovies, setUserMovies] = useState<{ original_title: string }[]>(
    []
  );

  const [idObject, setIdObject] = useState<{ [key: number]: true }>({});

  const checkLoginStatus = async () => {
    const isLoggedIn = await API.isLoggedIn();
    if (isLoggedIn) {
      const storedUser = await API.getUser();
      if (storedUser?.username) setUsername(storedUser?.username);
      await updateList();
    } else {
      navigate("/login");
    }
  };

  const getMyList = async () => {
    const myList = await API.getMyList();

    const idsObject = myList.data.reduce((acc, item) => {
      acc[item.id] = true; // You can set the value to true or any other value if needed
      return acc;
    }, {});
    setIdObject(idsObject);
  };

  const updateList = async () => {
    const myList = await API.getMyList();
    if (myList?.data?.length) {
      setMovieList(myList.data);
    }
  };

  useEffect(() => {
    getMyList();
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isConfirmationLoadingFinished) {
      setTimeout(() => {
        moviesRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }, 450);
    }
  }, [isConfirmationLoadingFinished]);

  const Title: React.FC = () => {
    const titleStyle: React.CSSProperties = {
      fontSize: "40px",
      color: "white",
      marginBottom: "13px",
    };

    const aiPoweredStyle: React.CSSProperties = {
      // fontFamily: "'Press Start 2P', sans-serif",
      color: "rgba(210, 213, 48, 1)",
      fontSize: "24px",
    };

    const titleSectionContainerStyle: React.CSSProperties = {
      marginBottom: "45px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    };

    return (
      <div style={titleSectionContainerStyle}>
        <div style={titleStyle}>Welcome to Movies Recommender</div>
        <span style={aiPoweredStyle}>AI-Powered</span>
      </div>
    );
  };

  const smartMovieSuggestionStyle: React.CSSProperties = {
    minHeight: "100vh",
    padding: "6vh",
  };

  return (
    <div>
      <NavBar
        showLogOutButton={true}
        username={username}
        showMyListIcon={true}
        listCount={45}
      />
      <div style={smartMovieSuggestionStyle}>
        <Title />
        <InputSec
          onInputChange={setUserInput}
          aiStartThinking={aiStartThinking}
        />
        <GetRecommendationsBtn
          userInput={userInput}
          setAiStartThinking={setAiStartThinking}
          setIsConfirmationLoadingFinished={setIsConfirmationLoadingFinished}
          setUserMovies={setUserMovies}
        />
      </div>
      {isConfirmationLoadingFinished ? (
        <div ref={moviesRef}>
          <MoviesPage userMovies={userMovies} idObject={idObject} />
        </div>
      ) : null}
    </div>
  );
};

export default SmartMovieSuggestion;
