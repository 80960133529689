/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling Entire File TS check until migration from JS is done.
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import MovieTrailer from "./MovieTrailer";

interface MoviePosterProps {
  userMovies: { original_title: string }[];
  movie: {
    poster_path: any;
    original_title: string;
  };
}

const MoviePoster: React.FC<MoviePosterProps> = ({ userMovies, movie }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const animateCard = async () => {
      setIsLoaded(false);
      // await delay(400);
      setIsLoaded(true);
    };

    animateCard();
  }, []);

  const posterContainerStyle: React.CSSProperties = {
    width: "16rem",
  };

  const moviePosterStyle: React.CSSProperties = {
    height: "21rem",
    borderRadius: "4%",
    marginTop: "-10rem",
    opacity: isLoaded ? 1 : 0,
    transform: isLoaded ? "scale(1) rotate(0deg)" : "scale(0.8) rotate(0deg)",
    transition: "opacity 0.4s ease-in-out, transform 0.4s ease-in-out",
  };

  return (
    <div style={posterContainerStyle}>
      {userMovies.length > 0 && (
        <img
          src={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
          style={moviePosterStyle}
          alt="Movie Poster"
        />
      )}
      <MovieTrailer userMovies={userMovies} />
    </div>
  );
};

export default MoviePoster;
