import axios from "axios";
import Logger from "../../modules/Logger";
import { DetailedMovie, Movie } from "./TMBDTypes";
const API_URL = "https://api.themoviedb.org/3/";
const API_KEY = "29de87d3f58e703ce82ba34e2460edcd";

class TMDBApi {
  private static formatPramaters(params: object) {
    let formatedParams = "";
    for (const [param, value] of Object.entries(params)) {
      formatedParams = `${formatedParams}&${param}=${value}`;
    }
    return formatedParams;
  }

  static async getGenres() {
    const url = `${API_URL}genre/movie/list?api_key=${API_KEY}`;
    return await axios
      .get(url)
      .then((res) => {
        return res.data.genres;
      })
      .catch((err) => {
        Logger.error(err);
        return undefined;
      });
  }
  static async discoverForRecommendations(
    with_genres = [],
    without_genres = [],
    page = 1
  ): Promise<undefined | { movies: Movie[]; hasMore: boolean }> {
    const url = `${API_URL}discover/movie?api_key=${API_KEY}&without_genres=${without_genres.join(
      ","
    )}&with_genres=${with_genres.join(",")}&page=${page}`;
    return await axios
      .get(url)
      .then((res) => {
        return { movies: res.data.results, hasMore: res.data.page < page };
      })
      .catch((err) => {
        Logger.error(err);
        return undefined;
      });
  }

  static async getSimilarMovies(movieId: number, page = 1) {
    const url = `${API_URL}movie/${movieId}/recommendations?api_key=${API_KEY}&page=${page}`;
    return await axios
      .get(url)
      .then((res) => {
        return res.data.results;
      })
      .catch((err) => {
        Logger.error(err);
        return undefined;
      });
  }

  static async searchMovies(
    query: string,
    peramateres = {}
  ): Promise<undefined | Movie[]> {
    const url = `${API_URL}search/movie?api_key=${API_KEY}&query=${query}&video=true${this.formatPramaters(
      peramateres
    )}`;
    return await axios
      .get(url)
      .then((res) => {
        return res.data.results;
      })
      .catch((err) => {
        Logger.error(err);
        return undefined;
      });
  }

  static async getImbdRating(
    movieId: number
  ): Promise<null | number | undefined> {
    const movie = await this.movieDetails(movieId);
    if (!movie?.imdb_id) return null;
    const options = {
      method: "GET",
      url: `https://moviesdatabase.p.rapidapi.com/titles/${movie?.imdb_id}/ratings`,
      headers: {
        "x-rapidapi-key": "2bdf1b0c0amsh3b0ae67d97f7bcbp1952a6jsnd0d9b5cd7ae4",
        "x-rapidapi-host": "moviesdatabase.p.rapidapi.com",
      },
    };
    try {
      const response = await axios.request(options);
      if (response.status === 200) {
        return response.data.results?.averageRating || null; // Return Rating or null if no rating found.
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
    }
  }

  static async movieDetails(
    movieId: number,
    extraMovieInfo = []
  ): Promise<undefined | DetailedMovie> {
    const url = `${API_URL}movie/${movieId}?api_key=${API_KEY}&append_to_response=${extraMovieInfo.join(
      ","
    )}`;
    return await axios
      .get(url)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        Logger.error(err);
        return undefined;
      });
  }
}

export default TMDBApi;
