import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavBar from "../UI/NavBar";
import LoginModal from "../UI/LoginModal";
import BlurDiv from "../UI/BlurDiv";
import API from "../APIs/API";
import { useNavigate } from "react-router";
import LoadingSpinner from "../UI/LoadingSpinner";

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const checkLogin = async () => {
      console.log("Loading Login Page");
      setLoading(true);
      const isLoggedIn = await API.isLoggedIn();
      if (isLoggedIn) {
        navigate("/");
      }
      setLoading(false);
    };

    checkLogin();
  }, [navigate]);

  const backgroundImage = "../StarsBG.jpg";

  return (
    <div style={{ height: "100%" }}>
      <NavBar
        username={undefined}
        showMyListIcon={false}
        showSearchBar={false}
        showLoginButton={false}
        showSignUpButton={true}
        showLogOutButton={false}
      />
      <BlurDiv style={{ height: "100%" }} blurDegree={3}>
        <StyledImage src={backgroundImage} alt="oops" />
      </BlurDiv>

      {loading ? <LoadingSpinner /> : <LoginModal />}
    </div>
  );
};

export default Login;
