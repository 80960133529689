/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling Entire File TS check until migration from JS is done.
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import imdbIcon from "../../assets/imdb.png";
import "../../Styles/GetRecommendationsBtn.css";
import { Typography, Popover, message } from "antd";
import API from "../../APIs/API";

interface EachMovieProps {
  userMovies: { original_title: string }[];
  movie: { original_title: string };
  idObject: { [key: number]: true };
}

const MovieInfo: React.FC<EachMovieProps> = ({
  userMovies,
  movie,
  idObject,
}) => {
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState(false);
  const [idsObject, setIdsObject] = useState<{ [key: number]: true }>({});

  useEffect(() => {
    setIdsObject(idObject);
    const delay = 500;
    const timeoutId = setTimeout(() => {
      setAnimationFinished(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  // Styles typed using React.CSSProperties
  const movieInfoStyle: React.CSSProperties = {
    width: "70%",
    marginTop: "-6rem",
    zIndex: 1,
    opacity: animationFinished ? 1 : 0,
    transform: animationFinished
      ? "translateX(0) scale(1)"
      : "translateX(-50px) scale(0)",
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  };

  const movieNameStyle: React.CSSProperties = {
    fontSize: "40px",
    lineHeight: "40px",
  };

  const timeGenraDateStyle: React.CSSProperties = {
    fontSize: "20px",
    marginBottom: "10px",
    color: "#C8C8C8",
  };

  const ratingsContainerStyle: React.CSSProperties = {
    display: "flex",
    gap: "40px",
  };

  const IMDBIconStyle: React.CSSProperties = {
    width: "3rem",
  };

  const RatingContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  const IMDBRatingStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#C8C8C8",
  };

  const rottenTomatoesIconStyle: React.CSSProperties = {
    width: "2.1rem",
  };

  const castStyle: React.CSSProperties = {
    fontSize: "18px",
    marginBottom: "12px",
    color: "#C8C8C8",
  };

  const descriptionStyle: React.CSSProperties = {
    fontSize: "17px",
    maxHeight: "140px", // Set your desired max height
    overflowY: "auto",
    color: "white",
    paddingRight: "15px",
    lineHeight: 1.3,
  };

  // Function to convert runtime to hours and minutes
  const convertRuntimeToHoursAndMinutes = (
    runtimeInMinutes: number
  ): string => {
    const hours = Math.floor(runtimeInMinutes / 60);
    const minutes = runtimeInMinutes % 60;
    return `${hours}h ${minutes}min`;
  };

  const buttonStyle: React.CSSProperties = {
    padding: "4px 12px", // Reduced top and bottom padding
    border: "none", // No border
    borderRadius: "4px", // Rounded corners
    backgroundColor: "#28a745", // Green for "Add" button
    color: "white", // White text color
    cursor: "pointer", // Pointer cursor on hover
    transition: "background-color 0.3s ease, transform 0.2s ease", // Smooth transition for hover effects
    fontSize: "14px", // Font size
    marginLeft: "10px", // Space between the button and the title
    height: "40px",
    marginTop: "-1rem",
  };

  // Hover and active states
  const buttonHoverStyle: React.CSSProperties = {
    backgroundColor: "#218838", // Darker green on hover
    transform: "scale(1.05)", // Slightly scale up the button
  };

  const buttonActiveStyle: React.CSSProperties = {
    backgroundColor: "#1e7e34", // Even darker green on active
  };

  // Styles for the remove button
  const removeButtonStyle: React.CSSProperties = {
    ...buttonStyle,
    backgroundColor: "#dc3545",
  };

  // Hover and active states for remove button
  const removeButtonHoverStyle: React.CSSProperties = {
    backgroundColor: "#c82333", // Darker red on hover
    transform: "scale(1.05)",
  };

  const removeButtonActiveStyle: React.CSSProperties = {
    backgroundColor: "#bd2130", // Even darker red on active
  };

  const handleAddMovie = async () => {
    await API.addMovieToUserList(
      movie.id,
      () => {
        message.success("You Added This Movie To Your List");
        const newIdsObject = { ...idsObject }; // Copy existing state
        newIdsObject[movie.id] = true; // Remove the id

        // Update state with the new object
        setIdsObject(newIdsObject);
      },
      () => {
        message.error("Failed To Add This Movie To Your List");
      }
    );
  };

  const handleRemoveMovie = () => {
    API.removeMovieFromMyList(
      movie.id,
      () => {
        message.success("You Removed This Movie From Your List");
        const newIdsObject = { ...idsObject }; // Copy existing state
        delete newIdsObject[movie.id]; // Remove the id

        // Update state with the new object
        setIdsObject(newIdsObject);
      },
      () => {
        message.error("Failed To Remove This Movie From Your List");
      }
    );
  };

  return (
    <div style={movieInfoStyle}>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <h2 style={movieNameStyle}>{movie.original_title}</h2>

        <button
          style={{
            ...(movie.id in idsObject ? removeButtonStyle : buttonStyle),
            ...(movie.id in idsObject
              ? removeButtonActiveStyle
              : buttonActiveStyle),
          }}
          onClick={movie.id in idsObject ? handleRemoveMovie : handleAddMovie}
          onMouseEnter={(e) => {
            if (movie.id in idsObject) {
              e.currentTarget.style.backgroundColor =
                removeButtonHoverStyle.backgroundColor;
            } else {
              e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor;
            }
          }}
          onMouseLeave={(e) => {
            if (movie.id in idsObject) {
              e.currentTarget.style.backgroundColor =
                removeButtonActiveStyle.backgroundColor;
            } else {
              e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor;
            }
          }}
        >
          {movie.id in idsObject ? "Remove from List" : "Add to List"}
        </button>
      </div>

      <div style={timeGenraDateStyle}>
        {convertRuntimeToHoursAndMinutes(movie.runtime)} /{" "}
        {movie.genres.map((eachGenre, i) =>
          i < movie.genres.length - 1
            ? eachGenre.name + ", "
            : eachGenre.name + " "
        )}{" "}
        / {movie.release_date}
      </div>

      <div style={ratingsContainerStyle}>
        <div style={RatingContainerStyle}>
          <img style={IMDBIconStyle} src={imdbIcon} alt="IMDB Icon" />
          <span style={IMDBRatingStyle}>{movie.ratings.imdb}/10</span>
        </div>
      </div>

      <div style={castStyle}>
        Cast:{" "}
        {movie.cast.map((eachMember, i) =>
          i < movie.cast.length - 1
            ? eachMember.name + ", "
            : eachMember.name + "...."
        )}
      </div>

      <div style={descriptionStyle}>{movie.overview}</div>
    </div>
  );
};

export default MovieInfo;
