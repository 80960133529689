/* eslint-disable @typescript-eslint/no-explicit-any */
// Disabling Entire File TS check until migration from JS is done.
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from "react";
import MovieCover from "./MovieCover";
import MoviePoster from "./MoviePoster";
import MovieInfo from "./MovieInfo";

interface EachMovieProps {
  userMovies: { original_title: string }[];
  currentMovie: number;
  idObject: { [key: number]: true };
}

const EachMovie: React.FC<EachMovieProps> = ({
  userMovies,
  currentMovie,
  idObject,
}) => {
  const movieDataSecStyle: React.CSSProperties = {
    display: "flex",
    paddingLeft: "17rem",
    marginTop: "-2.5rem",
  };

  return (
    <div>
      <MovieCover userMovies={userMovies} movie={userMovies[currentMovie]} />
      <div style={movieDataSecStyle}>
        <MoviePoster userMovies={userMovies} movie={userMovies[currentMovie]} />
        <MovieInfo
          userMovies={userMovies}
          movie={userMovies[currentMovie]}
          idObject={idObject}
        />
      </div>
    </div>
  );
};

export default EachMovie;
