import React, { useEffect, useState } from "react";

interface EachMovieProps {
  userMovies: { original_title: string }[]; // Accepting userMovies as props
  movie: {
    backdrop_path: any;
    original_title: string;
  };
}

const MovieCover: React.FC<EachMovieProps> = ({ userMovies, movie }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const animateCover = async () => {
      setIsLoaded(false);
      // await delay(400); // Adjust the delay as needed
      setIsLoaded(true);
    };

    animateCover();
  }, []);

  const movieCoverStyle: React.CSSProperties = {
    position: "relative",
    height: "50vh",
    overflow: "hidden",
  };

  const coverImageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transform: isLoaded ? "scale(1)" : "scale(0)",
    transition: "transform 0.5s ease-in-out",
  };

  const coverImageBlackLayerStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to top, #003157, transparent)",
    transition: "opacity 0.3s ease-in-out",
    opacity: isLoaded ? 0.7 : 0,
  };

  return (
    <div style={movieCoverStyle}>
      {userMovies.length > 0 && (
        <>
          <img
            src={`https://image.tmdb.org/t/p/original${movie.backdrop_path}`}
            style={coverImageStyle}
            alt={movie.original_title}
          />
          <div style={coverImageBlackLayerStyle}></div>
        </>
      )}
    </div>
  );
};

export default MovieCover;
